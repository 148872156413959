import { PublicKey } from '@solana/web3.js';
import { ProxyAccountInterface } from '../types/ProxyAccountInterface';
import React from 'react';
import { Link } from '@chakra-ui/react';

interface ProxyAddressDetailsProps {
    proxyPdaAccount: PublicKey,
    proxyAccount: ProxyAccountInterface
}

const url = (address: string): string => {
    return `https://solana.fm/address/${address}?cluster=mainnet-qn1`;
};

export const ProxyAddressDetails: React.FC<ProxyAddressDetailsProps> = (props: ProxyAddressDetailsProps) => {
    return <>
        Proxy Account: <Link
        className={'proxy-details'} isExternal
        href={url(props.proxyPdaAccount.toString())}>{props.proxyPdaAccount.toString()}</Link>
        <br />
        Current Admin: <Link className={'proxy-details'} isExternal
                             href={url(props.proxyAccount.admin.toString())}>{props.proxyAccount.admin.toString()}</Link>
        <br />
        Current Trader: <Link className={'proxy-details'} isExternal
                              href={url(props.proxyAccount.trader.toString())}>{props.proxyAccount.trader.toString()}</Link>
        <br />
        Current Manager: <Link className={'proxy-details'} isExternal href={url(
        props.proxyAccount.manager.toString())}>{props.proxyAccount.manager.toString()}</Link>
        <br />
        Current Treasurer: <Link className={'proxy-details'} isExternal href={url(
        props.proxyAccount.treasurer.toString())}>{props.proxyAccount.treasurer.toString()}</Link>
        <br />
    </>;
};
