import { Button, Input } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useAnchorWallet, useConnection } from '@solana/wallet-adapter-react';
import { TransactionSignature } from '@solana/web3.js';
import * as anchor from '@coral-xyz/anchor';
import { getProxyPdaAccount, proxyProgramID, SupportedAsset } from '../common';
import { ProxyAccountInterface } from '../types/ProxyAccountInterface';
import { AnchorProvider, BN, Idl } from '@coral-xyz/anchor';
import omsProxyIdl from '../omsProxyIdl.json';
import { getAssociatedTokenAddress } from '@solana/spl-token';
import { CryptoOptions } from './CryptoOptions';
import { assetsSupported } from './App';
import { useNotify } from './Notify';

export const ManageFunds: React.FC = () => {
    const { connection } = useConnection();
    const wallet = useAnchorWallet();
    const [proxyAccount, setProxyAccount] = useState<ProxyAccountInterface>();
    const [proxyProgram, setProxyProgram] = useState<any>();
    const [selectedOption, setSelectedOption] = useState<SupportedAsset>(assetsSupported[0]);
    const [amount, setAmount] = useState('');
    const [proxyPdaAccount] = getProxyPdaAccount();
    const notify = useNotify();

    const loadProxyProgram = useCallback(async () => {
        if (wallet) {
            const provider = new AnchorProvider(connection, wallet, { commitment: 'processed' });
            anchor.setProvider(provider);

            const proxyProgram = new anchor.Program(omsProxyIdl as Idl, proxyProgramID, provider);
            setProxyAccount(proxyAccount);
            setProxyProgram(proxyProgram);
        }
        // eslint-disable-next-line
    }, [connection, wallet])

    useEffect(() => {
        loadProxyProgram().catch(console.error);
    }, [loadProxyProgram]);


    const deposit = async () => {
        if (wallet) {
            const proxyATA = await getAssociatedTokenAddress(selectedOption.mint, proxyPdaAccount, true);
            const treasurerATA = await getAssociatedTokenAddress(selectedOption.mint, wallet.publicKey);
            try {
                const tx = await proxyProgram.methods
                    .deposit(new BN(amount))
                    .accounts({
                        proxy: proxyPdaAccount,
                        proxyAssociatedTokenAccount: proxyATA,
                        treasurer: wallet.publicKey,
                        treasurerAssociatedTokenAccount: treasurerATA,
                        tokenMint: selectedOption.mint,
                    })
                    .rpc();
                notify('success', tx);
            } catch (e) {
                notify('error')
                console.log('Deposit Failed : ', e);
            }
        }
    };

    const withdraw = async () => {
        if (wallet) {
            const proxyATA = await getAssociatedTokenAddress(selectedOption.mint, proxyPdaAccount, true);
            const treasurerATA = await getAssociatedTokenAddress(selectedOption.mint, wallet.publicKey);
            let signature: TransactionSignature | undefined = undefined;

            try {
                signature = await proxyProgram.methods
                    .withdraw(new BN(amount))
                    .accounts({
                        proxy: proxyPdaAccount,
                        proxyAssociatedTokenAccount: proxyATA,
                        treasurer: wallet.publicKey,
                        treasurerAssociatedTokenAccount: treasurerATA,
                        tokenMint: selectedOption.mint,
                    })
                    .rpc();

                notify('success', signature);
            } catch (error) {
                notify('error');
            }
        }
    };

    const onDeposit = useCallback(async () => {
        await deposit();
        // eslint-disable-next-line
    }, [wallet, connection, amount, selectedOption]);

    const onWithdraw = useCallback(async () => {
        await withdraw();
        // eslint-disable-next-line
    }, [wallet, connection, amount, selectedOption]);

    return <>
        <CryptoOptions setSelectedOption={setSelectedOption} selectedOption={selectedOption}/>
        <Input
            type='number'
            placeholder='Enter amount'
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
        />
        <Button onClick={onDeposit} disabled={!wallet?.publicKey} value={amount}>
            Deposit
        </Button>
        <Button onClick={onWithdraw}>
            Withdraw
        </Button>
    </>;
};
