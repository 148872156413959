import { Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import { SupportedAsset } from '../common';

interface CoinBalanceProps {
    refreshBalance: boolean,
    setRefreshBalance: (r: boolean) => void,
    coins: SupportedAsset[]
}

export const CoinBalance: React.FC<CoinBalanceProps>  = (props: CoinBalanceProps) => {
    return <>
        <TableContainer>
            <Table variant='simple'>
                <Thead fontSize="lg" fontWeight="bold">
                    <Tr>
                        <Td fontSize="xl" fontWeight="bold">Proxy coin balances</Td>
                        <Td><Button size="md"  onClick={() => props.setRefreshBalance(!props.refreshBalance)}>Refresh</Button></Td>
                    </Tr>
                    <Tr>
                        <Th>Coin</Th>
                        <Th>Balance</Th>
                    </Tr>
                </Thead>
                <Tbody fontSize="sm">
                    {props.coins.map((coin, index) => (
                        <Tr key={index}>
                            <Td>{coin.label}</Td>
                            <Td isNumeric>{coin.balance}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    </>;
};
