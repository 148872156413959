import { PublicKey } from '@solana/web3.js';
import usdcLogo from './logo/USDC_LOGO.png';
import solLogo from './logo/SOL_LOGO.png';
import hntLogo from './logo/HNT_LOGO.png';
import * as anchor from '@coral-xyz/anchor';

export const proxyProgramID = new PublicKey(process.env.REACT_APP_OMS_PROXY_PROGRAM_ID as string);

export const phoenixProgramId = "PhoeNiXZ8ByJGLkxNfZRnkUfjvmuYqLR89jjFHGqdXY";
export const whirlpoolProgramId = "whirLbMiicVdio4qvUfM5KAg6Ct8VwpYzGff3uctyCc"

export const cryptoLogos = {
    USDC: usdcLogo,
    SOL: solLogo,
    HNT: hntLogo,
};

export interface SupportedAsset {
    value: string;
    label: string;
    img: string;
    mint: PublicKey;
    balance?: string;
    decimals: number;
}

export interface SolanaFmInterface {
    blockTime: number;
    confirmationStatus: string;
    err: string;
    memo: string;
    signature: string;
    slot: number;
}


export interface TxHistoryInterface {
    signature: string;
    intruction: string | null;
}

export interface CoinAddressInterface {
    usdc: PublicKey,
    sol: PublicKey,
    hnt: PublicKey
}

export const coinAddresses: Record<string, CoinAddressInterface> = {
    MAINNET: {
        usdc: new PublicKey('EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'),
        sol: new PublicKey('So11111111111111111111111111111111111111112'),
        hnt: new PublicKey('hntyVP6YFm1Hg25TN9WGLqM12b8TQmcknKrdu1oxWux')
    },
    DEVNET: {
        usdc: new PublicKey('DK1gsSV2EubSE5S5FdXHpGzw2cAJNVzxeXRmAfxAMpU5'),
        sol: new PublicKey('B1sL3zxwyVnDGzRWCAsBkjL23wyu8HgwQP4XxgnHiSrv'),
        hnt: new PublicKey('H8UekPGwePSmQ3ttuYGPU1szyFfjZR4N53rymSFwpLPm')
    }
};

export function isValidSolanaAddress(inputValue: any) {
    return typeof inputValue === 'string' && (inputValue === '' || (inputValue.length >= 42 && inputValue.length <= 45));
}

export const getProxyPdaAccount = () => {
    return anchor.web3.PublicKey.findProgramAddressSync(
        [Buffer.from('proxy')],
        proxyProgramID,
    );
}
