import { Box, Button, Input, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { isValidSolanaAddress, getProxyPdaAccount } from '../common';
import { PublicKey } from '@solana/web3.js';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import { Idl, Program } from '@coral-xyz/anchor';
import { useNotify } from './Notify';

interface SetManagerProps {
    proxyProgram:  Program<Idl>
}

export const SetManager: React.FC<SetManagerProps> = (props: SetManagerProps) => {
    const [newManager, setNewManager] = useState<string>('');
    const [isNewManagerValid, setIsNewManagerValid] = useState<boolean>(true);
    const notify = useNotify();

    const wallet = useAnchorWallet();

    const onNewManagerChange = (e: any) => {
        const inputValue = e.target.value;
        const isValidInput = isValidSolanaAddress(inputValue);

        setNewManager(inputValue);
        setIsNewManagerValid(isValidInput);
    };

    const setManager = async () => {
        if (wallet && newManager) {
            const [proxyPdaAccount] = getProxyPdaAccount();

            try {
                const tx = await props.proxyProgram.methods
                    .setManager(new PublicKey(newManager))
                    .accounts({
                        proxy: proxyPdaAccount,
                        admin: wallet.publicKey,
                    })
                    .rpc();
                notify('success', tx);
            } catch (e) {
                notify('error');
            }
        }
    };

    return   <Box>
        <div className='components-row'>
            <Input
                type='text'  // Input type 'text' allows for string input
                placeholder='Enter New Manager'
                value={newManager}
                onChange={onNewManagerChange}
                borderColor={isNewManagerValid ? 'gray.200' : 'red.500'}  // Change border color based on validity
            />
            <Button onClick={setManager} isDisabled={!isNewManagerValid}>
                Change Manager
            </Button>
        </div>
        {!isNewManagerValid && (
            <Text color='red.500' mt='2' fontSize='sm'>
                {'Please enter a valid Admin address (42-45 characters).'}
            </Text>
        )}
    </Box>
};
