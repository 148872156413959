import { Button } from '@chakra-ui/react';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { getProxyPdaAccount, proxyProgramID } from '../common';
import * as anchor from '@coral-xyz/anchor';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import { Idl, Program } from '@coral-xyz/anchor';
import { MarketAccountInterface } from '../types/ProxyAccountInterface';
import { useNotify } from './Notify';

interface RemoveWhitelistedMarketProps {
    selectedPhoenixMarket: MarketAccountInterface | null;
    setSelectedPhoenixMarket: (x: MarketAccountInterface | null) => void;
    selectedWhirlpoolMarket: MarketAccountInterface | null;
    setSelectedWhirlpoolMarket: (x: MarketAccountInterface | null) => void;
    proxyProgram: Program<Idl>;
    selectedOption: string;
    setSelectedOption: Dispatch<SetStateAction<string>>;
}

export const RemoveWhitelistedMarket:React.FC<RemoveWhitelistedMarketProps> = (props: RemoveWhitelistedMarketProps) => {
    const wallet = useAnchorWallet();
    const [marketToRemove, setMarketToRemove] = useState<MarketAccountInterface>();
    const [marketToPause, setMarketToPause] = useState<MarketAccountInterface>();
    const notify = useNotify();

    async function removeWhitelistedMarket() {
        if (wallet && marketToRemove) {
            const [proxyPdaAccount] = getProxyPdaAccount();

            let [marketPda] = anchor.web3.PublicKey.findProgramAddressSync(
                [
                    marketToRemove.programAddress.toBuffer(),
                    marketToRemove.marketAddress.toBuffer(),
                ],
                proxyProgramID,
            );

            try {
                const tx = await props.proxyProgram.methods
                    .removeMarket()
                    .accounts({
                        market: marketPda,
                        proxy: proxyPdaAccount,
                        manager: wallet.publicKey,
                    })
                    .rpc();
                notify('success', tx);
            } catch (e) {
                console.log(e);
                notify('error');

            }
        }
    }

    async function pauseUnPauseMarket() {
        if (wallet && marketToPause) {
            const [proxyPdaAccount] = getProxyPdaAccount();

            let [marketPda] = anchor.web3.PublicKey.findProgramAddressSync(
                [
                    marketToPause.programAddress.toBuffer(),
                    marketToPause.marketAddress.toBuffer(),
                ],
                proxyProgramID,
            );

            if (marketToPause.isPaused) {
                try {
                    const tx = await props.proxyProgram.methods
                        .unpauseMarket()
                        .accounts({
                            market: marketPda,
                            proxy: proxyPdaAccount,
                            treasurer: wallet.publicKey,
                        })
                        .rpc();
                    notify('success', tx);
                } catch (e) {
                    console.log(e);
                    notify('error');
                }
            } else {
                try {
                    const tx = await props.proxyProgram.methods
                        .pauseMarket()
                        .accounts({
                            market: marketPda,
                            proxy: proxyPdaAccount,
                            treasurer: wallet.publicKey,
                        })
                        .rpc();
                    notify('success', tx);
                } catch (e) {
                    console.log(e);
                    notify('error');
                }
            }

        }
    }

    const removePhoenixMarket = async () => {
        props.setSelectedOption('Phoenix');
        if (props.selectedPhoenixMarket) {
            setMarketToRemove(props.selectedPhoenixMarket);
            await removeWhitelistedMarket();
        }
    };

    const removeWpMarket = async () => {
        props.setSelectedOption('Whirlpool');
        if (props.selectedWhirlpoolMarket) {
            setMarketToRemove(props.selectedWhirlpoolMarket);
            await removeWhitelistedMarket();
        }
    };

    const pauseWpMarket = async (market: string) => {
        if (market === 'Whirlpool' && props.selectedWhirlpoolMarket) {
            setMarketToPause(props.selectedWhirlpoolMarket);
            props.setSelectedOption('Whirlpool');
        }

        if (market === 'Phoenix' && props.selectedPhoenixMarket) {
            setMarketToPause(props.selectedPhoenixMarket);
            props.setSelectedOption('Phoenix');
        }

        await pauseUnPauseMarket();

    };

    return <>{props.selectedPhoenixMarket ? <div>
        <Button onClick={removePhoenixMarket} backgroundColor={'orange'}>
            {`Remove Phoenix Market ${props.selectedPhoenixMarket.marketAddress} ?`}
        </Button>
        <Button onClick={() => pauseWpMarket('Phoenix')} backgroundColor={'purple.300'}>
            {props.selectedPhoenixMarket.isPaused ? `Unpause` : 'Pause'}
        </Button>
        <Button onClick={() => props.setSelectedPhoenixMarket(null)} backgroundColor={'purple.200'}>
            {`Cancel`}
        </Button>
    </div> : ''}
        {(props.selectedWhirlpoolMarket && true) ? <div>
            <Button onClick={removeWpMarket} backgroundColor={'yellow'}>
                {`Remove Whirlpool Market ${props.selectedWhirlpoolMarket.marketAddress} ?`}
            </Button>
            <Button onClick={() => pauseWpMarket('Whirlpool')} backgroundColor={'purple.300'}>
                {props.selectedWhirlpoolMarket.isPaused ? `Unpause` : 'Pause'}
            </Button>
            <Button onClick={() => props.setSelectedWhirlpoolMarket(null)} backgroundColor={'purple.200'}>
                {`Cancel`}
            </Button>
        </div> : ''}
    </>;
};
