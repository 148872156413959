import React, { useCallback, useEffect, useState } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { MainProxy } from './MainProxy';
import { ManageFunds } from './ManageFund';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { CoinBalance } from './CoinBalances';
import {
    AccountLayout,
    TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import { useAnchorWallet, useConnection } from '@solana/wallet-adapter-react';
import { Image, Menu } from '@chakra-ui/react';
import { getProxyPdaAccount, SupportedAsset } from '../common';
import { Pause } from './Pause';
import { TransactionHistory } from './TransactionHistory';
import axios from 'axios';
import { assetsSupported } from './App';


export const Main: React.FC = () => {
    axios.defaults.headers.common['Authorization'] = process.env.REACT_APP_PORTAL_API_KEY;
    const { connection } = useConnection();
    const wallet = useAnchorWallet();
    const [refreshBalance, setRefreshBalance] = useState<boolean>(false);
    const [coins, setCoins] = useState<SupportedAsset[]>(assetsSupported);

    const updateCoinBalance = (coinName: string, newBalance: string) => {
        setCoins(prevCoins =>
            prevCoins.map(coin =>
                coin.value === coinName
                    ? { ...coin, balance: newBalance }
                    : coin,
            ),
        );
    };

    const fetchTokenBalances = useCallback(async () => {
        const [proxyPdaAccount] = getProxyPdaAccount();

        for (const coin of coins) {
            if (coin.label !== 'SOL') {
                try {
                    const tokenAccounts = await connection.getTokenAccountsByOwner(
                        proxyPdaAccount,
                        {
                            mint: coin.mint,
                            programId: TOKEN_PROGRAM_ID,
                        },
                    );
                    if (tokenAccounts)
                        tokenAccounts.value.forEach((tokenAccount) => {
                            const accountData = AccountLayout.decode(tokenAccount.account.data);

                            updateCoinBalance(coin.label,
                                (Number(accountData.amount) / Math.pow(10, coin.decimals)).toString());
                        });
                } catch (e) {
                    console.error(`Error fetchingtokenAccounts`, e);
                }
            } else {
                connection.getBalance(proxyPdaAccount)
                    .then(balance => updateCoinBalance('SOL', (balance / LAMPORTS_PER_SOL).toString()))
                    .catch(error => console.error('There was an error fetching the balance', error));
            }
        }
    }, [coins, connection]);

    useEffect(() => {
        fetchTokenBalances().catch(console.error);
        // eslint-disable-next-line
    }, [wallet, refreshBalance]);


    return wallet?.publicKey ?
        <div className='App'>
            <Menu>
                <WalletMultiButton />
            </Menu>
            {/* Proxy Information Row */}
            <div className='deposit-container'>
                <div className='info-row'>
                    <MainProxy />
                    <CoinBalance refreshBalance={refreshBalance} setRefreshBalance={setRefreshBalance} coins={coins} />
                    <TransactionHistory />
                    <Pause />
                </div>
                <div className='components-row'>
                    <ManageFunds />
                </div>
            </div>
        </div> : <div className='center-solo-button'>
            <Image src='https://assets.zyrosite.com/mjELM9qRjPCV4Qa6/yzdq-YD08gjJ6gLF9ZO4K.gif' />
            <WalletMultiButton />
        </div>;
};


