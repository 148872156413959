import React, { useCallback, useEffect, useState } from 'react';
import { PublicKey } from '@solana/web3.js';
import * as anchor from '@coral-xyz/anchor';
import { getProxyPdaAccount, phoenixProgramId, proxyProgramID, whirlpoolProgramId } from '../common';
import { MarketAccountInterface, ProxyAccountInterface } from '../types/ProxyAccountInterface';
import { useAnchorWallet, useConnection } from '@solana/wallet-adapter-react';
import { AnchorProvider, BorshAccountsCoder, Idl, Program } from '@coral-xyz/anchor';
import omsProxyIdl from '../omsProxyIdl.json';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { CheckIcon, ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import { ManageProxy } from './ManageProxy';
import { ProxyAddressDetails } from './ProxyAddressDetails';

export const MainProxy: React.FC = () => {
    const [selectedPhoenixMarket, setSelectedPhoenixMarket] = useState<MarketAccountInterface | null>(null);
    const [selectedWhirlpoolMarket, setSelectedWhirlpoolMarket] = useState<MarketAccountInterface | null>(null);
    const [proxyAccount, setProxyAccount] = useState<ProxyAccountInterface>();
    const [phoenixMarketAccounts, setPhoenixMarketAccounts] = useState<MarketAccountInterface[]>();
    const [whirlpoolMarketAccounts, setWhirlpoolMarketAccounts] = useState<MarketAccountInterface[]>();
    const [proxyProgram, setProxyProgram] = useState<Program<Idl>>();
    const [proxyPdaAccount] = getProxyPdaAccount();

    const { connection } = useConnection();
    const wallet = useAnchorWallet();

    const loadProxyInformations = useCallback(async () => {
        if (wallet) {
            const provider = new AnchorProvider(connection, wallet, { commitment: 'processed' });
            anchor.setProvider(provider);
            const localProxyProgram = new anchor.Program(omsProxyIdl as Idl, proxyProgramID, provider);
            const allAccounts = await connection.getProgramAccounts(proxyProgramID, { commitment: 'confirmed' });
            const borsh = new BorshAccountsCoder(localProxyProgram.idl);
            let phoenixMarkets: MarketAccountInterface[] = [];
            let whirlpoolMarkets: MarketAccountInterface[] = [];

            // eslint-disable-next-line array-callback-return
            allAccounts.map(account => {
                try {
                    const market = borsh.decode('Market', account.account.data);
                    if (new PublicKey(market.programAddress).toString() === phoenixProgramId)
                        phoenixMarkets.push(market);

                    if (new PublicKey(market.programAddress).toString() === whirlpoolProgramId)
                        whirlpoolMarkets.push(market);
                } catch (e) {
                    console.log(e);
                }
            });
            const proxyAccount: any = await localProxyProgram.account.proxy.fetch(proxyPdaAccount);
            setProxyAccount(proxyAccount as ProxyAccountInterface);
            setPhoenixMarketAccounts(phoenixMarkets);
            setWhirlpoolMarketAccounts(whirlpoolMarkets);
            setProxyProgram(localProxyProgram);
        }
        // eslint-disable-next-line
    }, [connection, wallet]);

    useEffect(() => {
        loadProxyInformations().catch(console.error);
    }, [loadProxyInformations]);


    return proxyAccount ? <div className='title'>
        <ProxyAddressDetails proxyPdaAccount={proxyPdaAccount} proxyAccount={proxyAccount} />
        <Menu>
            {({ isOpen }) => (
                <>
                    <MenuButton backgroundColor={'orange'} isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon />}>
                        {isOpen ? 'Close' : 'Whiteslisted Phoenix Markets'}
                    </MenuButton>
                    {phoenixMarketAccounts ? <MenuList backgroundColor={'orange'}>
                        {phoenixMarketAccounts.map((marketInfos, index) => (
                            <MenuItem backgroundColor={'orange'} key={index}
                                      onClick={() => setSelectedPhoenixMarket(marketInfos)} margin={0}>
                                {marketInfos.marketAddress.toString()} {marketInfos.isPaused ?
                                <CloseIcon marginLeft={'10px'} /> : <CheckIcon marginLeft={'10px'} />}
                            </MenuItem>
                        ))}
                    </MenuList> : ''}
                </>
            )}
        </Menu>
        <Menu>
            {({ isOpen }) => (
                <>
                    <MenuButton backgroundColor={'yellow'} isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon />}>
                        {isOpen ? 'Close' : 'Whiteslisted Whirlpool Markets'}
                    </MenuButton>
                    <Button onClick={loadProxyInformations}>
                        Reload-Accounts
                    </Button>
                    {whirlpoolMarketAccounts ? <MenuList backgroundColor={'yellow'}>
                        {whirlpoolMarketAccounts.map((marketInfos, index) => (
                            <MenuItem backgroundColor={'yellow'} key={index}
                                      onClick={() => setSelectedWhirlpoolMarket(marketInfos)} margin={0}>
                                {marketInfos.marketAddress.toString()} {marketInfos.isPaused ?
                                <CloseIcon marginLeft={'10px'} /> : <CheckIcon marginLeft={'10px'} />}

                            </MenuItem>
                        ))}
                    </MenuList> : ''}
                </>
            )}
        </Menu>

        {proxyProgram && <ManageProxy selectedPhoenixMarket={selectedPhoenixMarket}
                                      setSelectedPhoenixMarket={setSelectedPhoenixMarket}
                                      selectedWhirlpoolMarket={selectedWhirlpoolMarket}
                                      setSelectedWhirlpoolMarket={setSelectedWhirlpoolMarket}
                                      proxyProgram={proxyProgram} />}

    </div> : <></>;
}
