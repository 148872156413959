import { Box, Button, Input, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Idl, Program } from '@coral-xyz/anchor';
import { getProxyPdaAccount, isValidSolanaAddress } from '../common';
import { PublicKey } from '@solana/web3.js';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import { useNotify } from './Notify';

interface SetTreasurerProps {
    proxyProgram:  Program<Idl>
}

export const SetTreasurer: React.FC<SetTreasurerProps> = (props: SetTreasurerProps) => {
    const [isNewTreasurerValid, setIsNewTreasurerValid] = useState<boolean>(true);
    const [newTreasurer, setNewTreasurer] = useState<string>('');
    const notify = useNotify();

    const wallet = useAnchorWallet();

    const onNewAdminChange = (e: any) => {
        const inputValue = e.target.value;
        const isValidInput = isValidSolanaAddress(inputValue);

        setNewTreasurer(inputValue);
        setIsNewTreasurerValid(isValidInput);
    };

    const setTreasurer = async () => {
        if (wallet && newTreasurer) {
            const [proxyPdaAccount] = getProxyPdaAccount();

            try {
                const tx = await props.proxyProgram.methods
                    .setTreasurer(new PublicKey(newTreasurer))
                    .accounts({
                        proxy: proxyPdaAccount,
                        admin: wallet.publicKey,
                    })
                    .rpc();
                notify('success', tx);
            } catch (e) {
                notify('error');
            }
        }
    };

    return <Box>
        <div className='components-row'>
            <Input
                type='text'  // Input type 'text' allows for string input
                placeholder='Enter New Treasurer'
                value={newTreasurer}
                onChange={onNewAdminChange}
                borderColor={isNewTreasurerValid ? 'gray.200' : 'red.500'}  // Change border color based on validity
            />
            <Button onClick={setTreasurer} isDisabled={!isNewTreasurerValid}>
                Change Treasurer
            </Button>
        </div>
        {!isNewTreasurerValid && (
            <Text color='red.500' mt='2' fontSize='sm'>
                {'Please enter a valid Admin address (42-45 characters).'}
            </Text>
        )}
    </Box>;
};
