import React, { FC, ReactNode, useMemo } from 'react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter, WalletConnectWalletAdapter } from '@solana/wallet-adapter-wallets';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';

export const Web3Provider: FC<{ children: ReactNode }> = ({ children }) => {
    let network: WalletAdapterNetwork.Devnet | WalletAdapterNetwork.Mainnet;
    let rpc: string;
    let walletConnectId: string;
    if (process.env.REACT_APP_NETWORK_TYPE === 'DEVNET') {
        network = WalletAdapterNetwork.Devnet;
        rpc = process.env.REACT_APP_DEVNET_RPC ? process.env.REACT_APP_DEVNET_RPC : "localhost";
        walletConnectId = process.env.REACT_APP_WALLETCONNECT_ID_DEVNET ? process.env.REACT_APP_WALLETCONNECT_ID_DEVNET : "3e3a728d2371be485b6748a681a01abf"
    } else {
        network = WalletAdapterNetwork.Mainnet;
        rpc = process.env.REACT_APP_MAINNET_RPC ? process.env.REACT_APP_MAINNET_RPC : "localhost";
        walletConnectId = process.env.REACT_APP_WALLETCONNECT_ID_MAINNET ? process.env.REACT_APP_WALLETCONNECT_ID_MAINNET : "3e3a728d2371be485b6748a681a01abf"
    }


    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new WalletConnectWalletAdapter({
                network,
                options: {
                    relayUrl: 'wss://relay.walletconnect.com',
                    // example WC app project ID
                    projectId: walletConnectId,
                    metadata: {
                        name: 'OMS Proxy',
                        description: 'OMS Proxy web interface',
                        url: 'https://github.com/solana-labs/wallet-adapter',
                        icons: ['https://avatars.githubusercontent.com/u/35608259?s=200'],
                    },
                },
            }),
        ],
        [network, walletConnectId],
    );

    return (
        <ConnectionProvider endpoint={rpc}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};
