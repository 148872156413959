import React, { useState } from 'react';
import { Idl, Program } from '@coral-xyz/anchor';
import { SetAdmin } from './SetAdmin';
import { SetManager } from './SetManager';
import { WhitelistNewMarket } from './WhitelistNewMarket';
import { SetTreasurer } from './SetTreasurer';
import { RemoveWhitelistedMarket } from './RemoveWhitelistedMarket';
import { MarketAccountInterface } from '../types/ProxyAccountInterface';

interface ManageProxyProps {
    proxyProgram: Program<Idl>,
    selectedPhoenixMarket: MarketAccountInterface | null,
    setSelectedPhoenixMarket: (x: MarketAccountInterface | null) => void,
    selectedWhirlpoolMarket: MarketAccountInterface | null,
    setSelectedWhirlpoolMarket: (x: MarketAccountInterface | null) => void
}

export const ManageProxy: React.FC<ManageProxyProps> = (props: ManageProxyProps) => {
    const [selectedOption, setSelectedOption] = useState<string>('Whirlpool');


    return <>
        <RemoveWhitelistedMarket selectedPhoenixMarket={props.selectedPhoenixMarket}
                                 selectedWhirlpoolMarket={props.selectedWhirlpoolMarket}
                                 setSelectedPhoenixMarket={props.setSelectedPhoenixMarket}
                                 setSelectedWhirlpoolMarket={props.setSelectedWhirlpoolMarket}
                                 proxyProgram={props.proxyProgram}
                                 selectedOption={selectedOption}
                                 setSelectedOption={setSelectedOption} />
        <WhitelistNewMarket selectedMarketType={selectedOption} setSelectedOption={setSelectedOption}
                            proxyProgram={props.proxyProgram} />
        <SetAdmin proxyProgram={props.proxyProgram} />
        <SetManager proxyProgram={props.proxyProgram} />
        <SetTreasurer proxyProgram={props.proxyProgram} />
    </>;
}
