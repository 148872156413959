import React, { FC } from 'react';
import { Web3Provider } from './Web3Provider';
import { Main } from './Main';
import { ChakraProvider } from '@chakra-ui/react';
import * as buffer from "buffer";
import { theme } from '../mainTheme';
import "@fontsource/courier-prime";
import { coinAddresses, cryptoLogos, SupportedAsset } from '../common';
window.Buffer = buffer.Buffer;
require('../css/App.css');
require('@solana/wallet-adapter-react-ui/styles.css');

const NETWORK_TYPE = process.env.REACT_APP_NETWORK_TYPE || 'DEVNET';
export const addresses = coinAddresses[NETWORK_TYPE];

export const assetsSupported: SupportedAsset[] = [
    { value: 'USDC', label: 'USDC', img: cryptoLogos.USDC, mint: addresses.usdc, decimals: 6 },
    { value: 'SOL', label: 'SOL', img: cryptoLogos.SOL, mint: addresses.sol, decimals: 6 },
    { value: 'HNT', label: 'HNT', img: cryptoLogos.HNT, mint: addresses.hnt, decimals: 6 }
];

const App: FC = () => {
    return (
        <ChakraProvider theme={theme}>
            <Web3Provider>
                <Main />
            </Web3Provider>
        </ChakraProvider>
    );
};
export default App;
