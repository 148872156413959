import { Box, Button, Input, Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import React, { Dispatch, SetStateAction, useState } from 'react';
import * as anchor from '@coral-xyz/anchor';
import { getProxyPdaAccount, isValidSolanaAddress, phoenixProgramId, proxyProgramID, whirlpoolProgramId } from '../common';
import { PublicKey } from '@solana/web3.js';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import { Idl, Program } from '@coral-xyz/anchor';
import { useNotify } from './Notify';

interface WhitelistNewMarketProps {
    selectedMarketType: string;
    setSelectedOption: Dispatch<SetStateAction<string>>;
    proxyProgram:  Program<Idl>

}

export const WhitelistNewMarket: React.FC<WhitelistNewMarketProps> = (props: WhitelistNewMarketProps) => {
    const [isMarketValid, setIsMarketValid] = useState(true);
    const [whitelistNewMarket, setWhitelistNewMarket] = useState<string>('');
    const notify = useNotify();

    const wallet = useAnchorWallet();

    function getMarketProgramAddress() {
        let marketProgramAddress: PublicKey | null = null;
        switch (props.selectedMarketType) {
            case 'Whirlpool' :
                marketProgramAddress = new PublicKey(whirlpoolProgramId);
                break;
            case 'Phoenix' :
                marketProgramAddress = new PublicKey(phoenixProgramId);
                break;
            default :
                break;
        }
        return marketProgramAddress;
    }

    const newMarketInputChange = (e: any) => {
        const inputValue = e.target.value;
        const isValidInput = isValidSolanaAddress(inputValue);

        setIsMarketValid(isValidInput);
        setWhitelistNewMarket(inputValue);
    };

    const whitelistMarket = async () => {
        if (wallet && whitelistNewMarket) {
            const [proxyPdaAccount] = getProxyPdaAccount();

            let marketProgramAddress = getMarketProgramAddress();
            let newMarketAddress = new PublicKey(whitelistNewMarket);
            if (marketProgramAddress != null) {
                let [marketPda] = anchor.web3.PublicKey.findProgramAddressSync(
                    [
                        marketProgramAddress.toBuffer(),
                        newMarketAddress.toBuffer(),
                    ],
                    proxyProgramID,
                );

                try {
                    const tx = await props.proxyProgram.methods
                        .whitelistMarket()
                        .accounts({
                            programAddress: marketProgramAddress,
                            marketAddress: whitelistNewMarket,
                            market: marketPda,
                            proxy: proxyPdaAccount,
                            admin: wallet.publicKey,
                        })
                        .rpc();
                    notify('success', tx);
                } catch (e) {
                    notify('error');
                }
            }
        }
    };

    return <Box>
        <div className='components-row'>
            <Input
                type='text'  // Input type 'text' allows for string input
                placeholder='Enter Solana address'
                value={whitelistNewMarket}
                onChange={newMarketInputChange}
                borderColor={isMarketValid ? 'gray.200' : 'red.500'}  // Change border color based on validity
            />
            <Button onClick={whitelistMarket} isDisabled={!isMarketValid}>
                Whitelist
            </Button>
            <RadioGroup
                mt='4'
                value={props.selectedMarketType}
                onChange={props.setSelectedOption}
            >
                <Stack direction='row' spacing='4'>
                    <Radio value='Whirlpool'>Whirlpool</Radio>
                    <Radio value='Phoenix'>Phoenix</Radio>
                </Stack>
            </RadioGroup>
        </div>
        {!isMarketValid && (
            <Text color='red.500' mt='2' fontSize='sm'>
                {'Please enter a valid Market address (42-45 characters).'}
            </Text>
        )}
    </Box>
};
