import { extendTheme } from '@chakra-ui/react';


export const theme = extendTheme({
    styles: {
        global: {
            "App, body": {
                background: "linear-gradient(270deg, #AB66FF 0%, #14F195 101.39%)",
            },
            "button" : {
                margin: "5px"
            }
        },
    },
});
