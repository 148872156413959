import { Button } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import * as anchor from '@coral-xyz/anchor';
import { getProxyPdaAccount, proxyProgramID } from '../common';
import { useAnchorWallet, useConnection } from '@solana/wallet-adapter-react';
import { AnchorProvider, Idl, Program } from '@coral-xyz/anchor';
import omsProxyIdl from '../omsProxyIdl.json';
import { Transaction } from '@solana/web3.js';
import { useNotify } from './Notify';

export const Pause: React.FC = () => {
    const { connection } = useConnection();
    const wallet = useAnchorWallet();
    const [paused, setPaused] = useState<boolean>(false);
    const [proxyProgram, setProxyProgram] = useState<Program>();
    const [proxyPdaAccount] = getProxyPdaAccount();
    const [txid, setTxId] = useState('');
    const notify = useNotify();

    const fetchProgramState = useCallback(async () => {
        if (wallet) {
            const provider = new AnchorProvider(connection, wallet, { commitment: 'processed' });
            anchor.setProvider(provider);
            const proxyProgram = new anchor.Program(omsProxyIdl as Idl, proxyProgramID, provider);
            setProxyProgram(proxyProgram);
            try {
                const proxyAccount = await proxyProgram.account.proxy.fetch(proxyPdaAccount);
                if (proxyAccount)
                    setPaused(proxyAccount.isPaused as boolean);
            } catch (e) {
                console.log(e);
            }
        }
        // eslint-disable-next-line
    }, [wallet, txid]);

    useEffect(() => {
        fetchProgramState().catch(console.error);
    }, [fetchProgramState]);


    const pauseAllMarkets = async () => {
        if (wallet && proxyProgram) {
            try {
                const tx = await proxyProgram.methods
                    .pauseProxy()
                    .accounts({
                        proxy: proxyPdaAccount,
                        manager: wallet.publicKey,
                    })
                    .rpc();
                notify('success');
                setTxId(tx);
            } catch (e) {
                console.log('Paused Failed : ', e);
                notify('error');
            }
        }
    };

    const unPauseAllMarkets = async () => {
            if (wallet && proxyProgram) {
                let tx = new Transaction();

                try {

                    let ix = await proxyProgram.methods
                        .unpauseProxy()
                        .accounts({
                            proxy: proxyPdaAccount,
                            manager: wallet.publicKey,
                        }).instruction();

                    tx.add(ix);
                    tx.feePayer = (wallet.publicKey as any);
                    let blockhashObj = anchor.getProvider().connection.getLatestBlockhash();
                    tx.recentBlockhash = (await blockhashObj).blockhash;

                    try {
                        const signedTx = await wallet.signTransaction(tx);
                        try {
                            const txId = await connection.sendRawTransaction(signedTx.serialize(),
                                { preflightCommitment: 'confirmed' });

                            notify('success', txId);
                            setTxId(txId);
                            // Do something with signedTx if needed...
                        } catch (error) {
                            notify('error');
                        }
                        // Do something with signedTx if needed...
                    } catch (error) {

                    }
                    //const signatureStatus = await connection.getSignatureStatus(txId);
                } catch (e) {
                    console.log('Paused Failed : ', e);
                }
            }
        }
    ;

    return wallet ? <div>
        Proxy : {paused ? 'Paused' : 'Not Paused'}
        {!paused ? <Button onClick={pauseAllMarkets} backgroundColor={'purple.400'}>
                Pause All Markets ⚠️
            </Button> :
            <Button onClick={unPauseAllMarkets} backgroundColor={'purple.400'}>
                Unpause All Markets ⚠️
            </Button>}
    </div> : <>
    </>;

};
